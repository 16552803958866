import { NoTranslationFound } from "components/NoTranslationFound"

/**
 * Contentful の element モデルの html をレンダリングするコンポーネント
 * 英語データがない場合は element が空配列になり、翻訳されたページがないという表示をする
 */
export const ContentRenderer = ({ element, className = undefined }) => {
  if (!Array.isArray(element)) {
    throw new Error("element is not array")
  }

  return element.length > 0 ? (
    element.map(({ node }) => {
      const html = JSON.parse(JSON.stringify(node.html.html))

      return (
        <div key={node.id} className="main_area inviewfadeInUp">
          <div
            key={node.id}
            className={className} // "general_box" があったりなかったりページよる
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      )
    })
  ) : (
    <NoTranslationFound />
  )
}
