import { useEffect } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useLocalization, LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"

import Footer from "components/Footer"
import { PageTopAnchor } from "components/PageTopAnchor"
import { PcFooterLink } from "components/FooterLink/Pc"
import { MoFooterLink } from "components/FooterLink/Mo"
import { MoFooterWrap } from "components/FooterWrap"
import { PhoneArea } from "components/PhoneArea"
import { BannerArea } from "components/BannerArea"
import { OurHospitalNavi } from "components/OurHospitalNavi"
import { ContentRenderer } from "components/ContentRenderer"
import { useCmsContext } from "context/Cms"

const IndexPage = ({ data }) => {
  const { locale } = useLocalization()
  const { t } = useTranslation()
  const { changeShowButton } = useCmsContext()

  const localizedElement = data.allContentfulElement.edges.filter(
    ({ node }) => node.node_locale.slice(0, 2) === locale && node.html != null,
  )

  useEffect(() => {
    const enElement = data.allContentfulElement.edges.filter(
      ({ node }) => node.node_locale.slice(0, 2) === "en" && node.html != null,
    )

    const showButton = locale === "en" ? true : enElement.length > 0
    changeShowButton(showButton)
  }, [data.allContentfulElement.edges])
  return (
    <main>
      <Helmet
        title={
          t("子供の治療用メガネ", "子供の治療用メガネ") +
          t("(斜視・弱視)", "(斜視・弱視)")
        }
      />
      <div className="sub_page">
        <section className="page_header service inviewfadeInUp">
          <div className="container2">
            <h1>
              <span>{t("当院について")}</span>
              {t("子供の治療用メガネ", "子供の治療用メガネ")}
              {t("(斜視・弱視)", "(斜視・弱視)")}
            </h1>
          </div>
        </section>

        <div className="container2">
          <section className="breadcrumb">
            <ul>
              <li>
                <Link to="/">{t("星井眼科医院TOP", "星井眼科医院TOP")}</Link>
              </li>
              <li>
                <Link to="/our_hospital_menu">
                  {t("当院について", "当院について")}
                </Link>
              </li>
              <li>
                {t("子供の治療用メガネ", "子供の治療用メガネ")}
                {t("(斜視・弱視)", "(斜視・弱視)")}
              </li>
            </ul>
          </section>

          <section className="content">
            <ContentRenderer
              element={localizedElement}
              className="general_box"
            />

            <OurHospitalNavi />
          </section>
        </div>
      </div>
      <div className="container2">
        <section className="content">
          <PhoneArea />
          <BannerArea />
        </section>
      </div>
      <section className="content">
        <div className="footer_link inviewfadeInUp">
          <PcFooterLink />
          <MoFooterLink />
        </div>
      </section>

      <Footer />
      <PageTopAnchor />
      <MoFooterWrap />
    </main>
  )
}

export default IndexPage

export const query = graphql`
  query childrenglasses_allContentfulElement {
    allContentfulElement(filter: { name: { eq: "eyewear" } }) {
      edges {
        node {
          id
          title
          html {
            html
          }
          node_locale
        }
      }
    }
  }
`
